import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import { Link, NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
  Form,
} from "reactstrap";

const FormComponent = ({ addData, currentPackageData }) => {
  //console.log("currentPackageData", currentPackageData);

  const [packageData, setPackageData] = useState({
    // Existing fields
    package_type: currentPackageData?.package_type || "regular",
    title: currentPackageData?.title || "",
    packages_description: currentPackageData?.packages_description || "",
    amount: currentPackageData?.amount || 0,
    discount: currentPackageData?.discount || 0,
    reservationAmount: currentPackageData?.reservationAmount || 0,
    pricePerBrunch: currentPackageData?.pricePerBrunch || 0,
    // New fields
    qty: currentPackageData?.qty || 1,
    leftQtyAlert: currentPackageData?.leftQtyAlert || 0,
    buyQty: currentPackageData?.buyQty || 0,
    getQty: currentPackageData?.getQty || 0,
  });

  const [Discount, setDiscount] = useState(0);

  const updateValue = (index, value) => {
    const list = { ...packageData };
    list[index] = value;
    setPackageData(list);
  };

  // const addDataPackage = (e) => {
  //   //e.preventDefault();

  //   // if (!packageData.title) {
  //   //   alert("Title is required");
  //   //   return false;
  //   // }

  //   if (!packageData.amount) {
  //     alert("Please enter amount");
  //     return false;
  //   }

  //   if (packageData.amount) {
  //     if (packageData.amount < 0) {
  //       alert("Please enter valid amount");
  //       return false;
  //     }
  //   }

  //   if(packageData.reservationAmount > 0){
  //     if (packageData.reservationAmount < 1 || packageData.reservationAmount > 99 ) {
  //       alert("Please enter valid reservation amount");
  //       return false;
  //     }
  //   }

  //   if (packageData.discount > 0) {
  //     // check discount less then 5 or greater then 99

  //     if (packageData.discount < 5 || packageData.discount > 99) {
  //       alert("Please enter valid discount (5-99)");
  //       return false;
  //     }
  //   }

  //   // if(!packageData.discount){
  //   //   alert("Please enter discount");
  //   //   return false;
  //   // }

  //   // if (packageData.discount) {
  //   //   if (packageData.discount < 5 || packageData.discount > 99) {
  //   //     alert("Please enter valid discount");
  //   //     return false;
  //   //   }
  //   // }

  //   // if (packageData.claimCode){
  //   //   if (packageData.claimCode.length != 8) {
  //   //     alert("Please enter 8 digit claim code");
  //   //     return false;
  //   //   }

  //   //   if (/^[A-Za-z]{2}\d{6}$/.test(packageData.claimCode) === false) {
  //   //     alert("Please enter valid claim code format (WY123456)");
  //   //     return false;
  //   //   }
  //   // }

  //   // if (packageData.qty) {
  //   //   if (packageData.qty < 1) {
  //   //     alert("Please enter valid qty");
  //   //     return false;
  //   //   }
  //   // }

  //   if (packageData.qty) {
  //     if (packageData.qty < 1) {
  //       alert("Please enter valid qty");
  //       return false;
  //     }
  //   }

  //   if (packageData.leftQtyAlert) {
  //     if (packageData.leftQtyAlert < 1) {
  //       alert("Please enter valid left qty alert");
  //       return false;
  //     }
  //   }

  //   // if (packageData.qty < packageData.leftQtyAlert) {
  //   //   alert("can not set alert less than qty");
  //   //   return false;
  //   // }

  //   // if (packageData.pricePerBrunch) {
  //   //   alert("Please enter price per brunch");
  //   //   return false;
  //   // }

  //   // pricePerBrunch is less than amount
  //   if (packageData.pricePerBrunch > packageData.amount) {
  //     alert("Claim amount should be less than amount");
  //     return false;
  //   }

  //   if (packageData.pricePerBrunch < 0) {
  //     alert("Please enter valid price per brunch");
  //     return false;
  //   }

  //   if (packageData.package_type === "group") {
  //     // check buy qty and get qty

  //     if (packageData.buyQty < 1) {
  //       alert("Please enter valid buy qty");
  //       return false;
  //     }

  //     if (packageData.getQty < 1) {
  //       alert("Please enter valid get qty");
  //       return false;
  //     }
  //   }

  //   // if(packageData?.discount=0||null){
  //   //   ClaimAmount();
  //   // }
  //   const packData = { ...packageData };
  //   addData(packageData);
  //   packData.title = "";
  //   packData.packages_description = "";
  //   packData.isFeatured = false;
  //   packData.qty = 0;
  //   packData.leftQtyAlert = 0;
  //   packData.pricePerBrunch = 0;
  //   packData.amount = 0;
  //   packData.discount = 0;
  //   packData.reservationAmount = 0;

  //   // packData.claimCode = 0;
  //   //setPackageData(packData);
  // };


  const addDataPackage = (e) => {
    e.preventDefault();
  
    // Existing validations
    if (!packageData.title) {
      alert("Title is required");
      return false;
    }
  
    if (!packageData.amount) {
      alert("Please enter amount");
      return false;
    }
  
    if (packageData.amount < 0) {
      alert("Please enter valid amount");
      return false;
    }
  
    if (packageData.discount > 0) {
      if (packageData.discount < 5 || packageData.discount > 99) {
        alert("Please enter valid discount (5-99)");
        return false;
      }
    }
  
    if (!packageData.qty || packageData.qty < 1) {
      alert("Please enter valid quantity (minimum 1)");
      return false;
    }
  
    if (packageData.leftQtyAlert < 0) {
      alert("Left Quantity Alert cannot be negative");
      return false;
    }
  
    if (parseInt(packageData.leftQtyAlert) > parseInt(packageData.qty)) {
      console.log(packageData.leftQtyAlert, packageData.qty);
      alert("Left Quantity Alert cannot be greater than Quantity");
      return false;
    }
  
    if (packageData.package_type === "group") {
      if (!packageData.buyQty || packageData.buyQty < 1) {
        alert("Please enter valid Buy Quantity (minimum 1)");
        return false;
      }
  
      if (!packageData.getQty || packageData.getQty < 1) {
        alert("Please enter valid Get Quantity (minimum 1)");
        return false;
      }
    }
  
    // Existing code to handle submission
    addData(packageData);
    // Reset form fields if necessary
    setPackageData({
      // Reset to default values
      package_type: "regular",
      title: "",
      packages_description: "",
      amount: 0,
      discount: 0,
      reservationAmount: 0,
      pricePerBrunch: 0,
      qty: 1,
      leftQtyAlert: 0,
      buyQty: 0,
      getQty: 0,
    });
  };

  const Percentagemange = (e) => {
    let value = e.target.value;
    //console.log({ value });
    if (value == 0) {
      setDiscount(0);
    } else {
      setDiscount(5);
    }
  };

  const Percentagemange2 = (e) => {
    let value = e.target.value;
    //console.log({ value });
    if (value == 0) {
      setAmount(0);
    } else {
      setAmount(5);
    }
  };

  const ClaimAmount = (e) => {
    e.preventDefault();
    let presentVenue = (packageData.amount / 100) * packageData.discount;
    presentVenue = presentVenue.toFixed(2);   
    addDataPackage(e);


  };

  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="">
                <Form onSubmit={ClaimAmount}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("package_type", e.target.value)
                              }
                              value={packageData?.package_type || "regular"}
                              name="allowWhosin"
                              className="form-select"
                            >
                              <option value="regular" selected>
                                Regular
                              </option>
                              <option value="group">Group</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"                              
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={packageData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue(
                                  "packages_description",
                                  e.target.value
                                )
                              }
                              value={
                                packageData?.packages_description ||
                                packageData?.description
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Amount
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              required
                              onChange={(e) =>
                                updateValue("amount", e.target.value)
                              }
                              value={packageData?.amount}
                              name="amount"
                              min="0"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount In Percentage
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) => {
                                updateValue("discount", e.target.value),
                                  Percentagemange(e);
                              }}
                              value={packageData?.discount}
                              name="discount"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Reservation Amount (%)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) => {
                                updateValue("reservationAmount", e.target.value)
                                  
                              }}
                              value={packageData?.reservationAmount}
                              name="reservationAmount"
                              className="form-control"
                            />
                          </div>
                        </div>

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Claim Code
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              required
                              onChange={(e) => {
                                const inputClaimCode = e.target.value;
                                // Check if the input is a 4-digit number                                
                                  updateValue("claimCode", inputClaimCode);                                                           
                              }}
                              value={packageData?.claimCode}
                              name="claimCode"                             
                              className="form-control"
                              placeholder="WY123456"
                            />
                          </div>
                        </div> */}
                     

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Is Featured
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="checkbox"
                              checked={packageData.isFeatured}
                              onChange={(e) =>
                                updateValue("isFeatured", e.target.checked)
                              }
                            />
                          </div>
                        </div> */}

                        {/* Quantity */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Quantity</label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) => updateValue("qty", e.target.value)}
                              value={packageData.qty}
                              name="qty"
                              className="form-control"
                              min="1"
                            />
                          </div>
                        </div>

                        {/* Left Quantity Alert */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Left Quantity Alert</label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) => updateValue("leftQtyAlert", e.target.value)}
                              value={packageData.leftQtyAlert}
                              name="leftQtyAlert"
                              className="form-control"
                              min="0"
                            />
                          </div>
                        </div>

                        {/* Conditional fields for Group type */}
                        {packageData.package_type === "group" && (
                          <>
                            {/* Buy Quantity */}
                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">Buy Quantity</label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  onChange={(e) => updateValue("buyQty", e.target.value)}
                                  value={packageData.buyQty}
                                  name="buyQty"
                                  className="form-control"
                                  min="1"
                                />
                              </div>
                            </div>

                            {/* Get Quantity */}
                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">Get Quantity</label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  onChange={(e) => updateValue("getQty", e.target.value)}
                                  value={packageData.getQty}
                                  name="getQty"
                                  className="form-control"
                                  min="1"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
};

const PackageModal = ({ show, closeModal, addData, currentPackageData }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Package Info
        </h5>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <FormComponent
          addData={addData}
          currentPackageData={currentPackageData}
        />
      </div>
    </Modal>
  );
};

export default PackageModal;
