import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

const TableComponent = ({ selectOfferRow, venueId, multipleSelect }) => {
  const [offerdata, setofferdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState(10);
  const [sort, setsort] = useState("");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [searchText, setSearchText] = useState("");

  // New state variables for selected rows
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  useEffect(() => {
    offerManagment();
  }, [currentPage, sizePerPageOrg, sort]);

  const handleTableChange = (type, { page }) => {
    if (type === "pagination" && page !== currentPage) {
      setCurrentPage(page);
    }
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img src={row.image} alt="img" width={"50px"} />
      </>
    );
  }

  function BusinessFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.venueName}</p>
      </div>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  function iconFormatter(cellContent, row) {
    return (
      <div className="d-flex">
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => selectOfferRow(row, row._id, row.title)}
        >
          <i className="fal fa-plus fs-5"></i>
        </button>
      </div>
    );
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "title",
      text: "Title",
      sort: false,
    },
    {
      text: "Venue Name",
      formatter: BusinessFormatter,
    },
    {
      dataField: "image",
      text: "Image",
      formatter: ImgLogoFormatter,
      sort: false,
    },
    {
      dataField: "select",
      text: "Select",
      formatter: iconFormatter,
      sort: false,
    },
  ];

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };

  const handlesorting = (event) => {
    setsort(event.target.value);
  };

  const handleReload = () => {
    window.location.reload();
  };

  // Select row configuration
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: (row, isSelect) => handleRowSelect(row, isSelect),
    onSelectAll: (isSelect, rows) => handleSelectAll(isSelect, rows),
  };

  const handleRowSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows((prev) => [...prev, row]);
      setSelectedRowIds((prev) => [...prev, row._id]);
    } else {
      setSelectedRows((prev) => prev.filter((r) => r._id !== row._id));
      setSelectedRowIds((prev) => prev.filter((id) => id !== row._id));
    }
  };

  const handleSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setSelectedRows(rows);
      setSelectedRowIds(rows.map((r) => r._id));
    } else {
      setSelectedRows([]);
      setSelectedRowIds([]);
    }
  };

  const limit = 10;

  const offerManagment = async () => {
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: "",
      venueId: venueId,
      expired: false,
    };

    if (searchText) {
      payload.search = searchText;
    }

    const response = await postRequest(`venue/offer/list/admin`, payload);
    const data = response.data;
    setTotalPage(data.count);
    setCurrentFPage(data.page);
    setofferdata(data.list);
    setLoader(false);
  };

  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory({
                      page: currentFPage,
                      sizePerPage: sizePerPageOrg,
                      totalSize: totalPage,
                      custom: true,
                    })}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <>
                        <ToolkitProvider
                          keyField="_id"
                          data={offerdata}
                          columns={columns}
                        >
                          {(props) => (
                            <Row>
                              <Col className="col-lg-12 d-flex mb-3">
                                <div className="w-10 d-flex">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ marginRight: "90px" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>
                                </div>

                                <div className="d-flex g-3 justify-content-end">
                                  <input
                                    type="search"
                                    placeholder="Search"
                                    value={searchText}
                                    className="form-control"
                                    onChange={(e) =>
                                      setSearchText(e.target.value)
                                    }
                                    style={{ width: "300px" }}
                                  />
                                  <button
                                    onClick={offerManagment}
                                    type="button"
                                    className="btn btn-primary mx-2"
                                  >
                                    Search
                                  </button>
                                  <Link to="/createOffer">
                                    <button className="btn btn-secondary">
                                      <i className="fas fa-plus"></i> Create
                                    </button>
                                  </Link>
                                  {selectedRows.length > 0 && (
                                    <button
                                      type="button"
                                      className="btn btn-primary mx-2"
                                      onClick={() =>
                                        multipleSelect(selectedRows, selectedRowIds)
                                      }
                                    >
                                      Select All
                                    </button>
                                  )}
                                </div>
                              </Col>

                              <Col className="col-lg-12 text-end">
                                <BootstrapTable
                                  onTableChange={handleTableChange}
                                  remote
                                  classes="table-striped"
                                  {...props.baseProps}
                                  {...paginationTableProps}
                                  selectRow={selectRow}
                                />
                              </Col>
                            </Row>
                          )}
                        </ToolkitProvider>

                        <Row>
                          <Col className="col-lg-12 text-end">
                            <div className="text-md-right">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </PaginationProvider>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
};

const OfferModal = ({ show, closeModal, selectRow, venueId, multipleSelect }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Offer List
        </h5>
        <button
          onClick={closeModal}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <TableComponent
          selectOfferRow={selectRow}
          venueId={venueId}
          multipleSelect={multipleSelect}
        />
      </div>
    </Modal>
  );
};

export default OfferModal;
