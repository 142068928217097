import React, { useState, useEffect, useRef } from "react";
import ReactCrop from "react-image-crop";
import { useHistory, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  postRequestForm,
  pushUpdates,
} from "../../components/Common/Utils.js";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import './venueTime.css';

import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import Lightbox from "react-image-lightbox";
import VenueModal from "../../components/Modal/VenueModalOrg.js";
import HolidayModal from "../../components/Modal/HolidayModal.js";
import FileUpload from "../../components/Common/FileUpload";

import OfferPackageModal from "../../components/Modal/OfferPackageModal.js";
import { set } from "lodash";

export default function CreateOffer() {
  const location = useLocation();
  const state = location.state?.row;

  const [validTimeRange, setValidTimeRange] = useState({ startTime: '', endTime: '', range: '' });

  const [offerData, setHomeBlockData] = useState({ type: "video" });
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [rectangleImage, setRectangleImage] = useState(null);
  const [squareImage, setSquareImage] = useState(null);
  const [storyImage, setStoryImage] = useState(null);


  const [remoteRectangle, setRemoteRectangle] = useState(null);
  const [remoteSquare, setRemoteSquare] = useState(null);
  const [remoteStory, setRemoteStory] = useState(null);


const [rectangleImagePreview, setRectangleImagePreview] = useState(null);
const [squareImagePreview, setSquareImagePreview] = useState(null);
const [storyImagePreview, setStoryImagePreview] = useState(null);

const [rectangleSelected, setrectangleSelected] = useState(0);
const [squareSelected, setSquareSelected] = useState(0);
const [storySelected, setStorySelected] = useState(0);

  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [venueList, setVenueList] = useState([]);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [venueTimings, setVenueTimings] = useState([]);
  const [venueDays, setVenueDays] = useState([]);
  const [completedCrop2, setCompletedCrop2] = useState(null);
  const [completedCrop3, setCompletedCrop3] = useState(null);
  const [cropImage, setCropImage] = useState(null);
  const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  const [availableTime, setAvailableTime] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);

  const [regularTime, setRegularTime] = useState({
    start: "",
    end: "",
  });

  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });


  const [cropRectangle, setCropRectangle] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 540,
    height: 300,
    aspect: 1.8,
    maxWidth: 540,
    maxHeight: 300,
    minHeight: 300,
    minWidth: 540,
  });


  const [cropSquare, setCropSquare] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 300,
    height: 300,
    aspect: 1,
    maxWidth: 300,
    maxHeight: 300,
    minHeight: 300,
    minWidth: 300,
  });


  const updateCropRectangle = (crop) => {
    const aspectRatio = 1.8; // Desired aspect ratio (1.8:1)
    
    // Calculate the new width and height based on the aspect ratio
    if (crop.width / crop.height !== aspectRatio) {
      crop.width = crop.height * aspectRatio;
      crop.height = crop.width / aspectRatio;
    }
  
    setCropRectangle(crop);
  };

  const updateCropSquare = (crop) => {
    const aspectRatio = 1; // Desired aspect ratio (1:1)

    // Calculate the new width and height based on the aspect ratio
    if (crop.width / crop.height !== aspectRatio) {
      crop.width = crop.height * aspectRatio;
      crop.height = crop.width / aspectRatio;
    }

    setCropSquare(crop);
  };

  

  



  const blobUrlRef = useRef("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [openPackage, setOpenPackage] = useState(false);
  const [dayList, setDayList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryText, setCategoryText] = useState("");
  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedDay, setSelectedDay] = useState([]);
  const [dateInputValue, setDateInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [model, setModel] = useState(false);
  const [packages, setPackages] = useState([]);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editingIndex1, setEditingIndex1] = useState(null);
  const [currentPackageData, setCurrentPackageData] = useState({});
  const [modelHoliday, setModelHoliday] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState([]);



  const today = new Date().toISOString().split("T")[0];

  // apply useeffect for venueDays

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    return `${((+hours % 12) || 12)}:${minutes} ${+hours < 12 ? 'AM' : 'PM'}`;
  };

  const isClosed = (timing) => !timing;

  // Function to render the status badge
  const renderStatusBadge = (isOpen) => (
    <span style={{
      display: 'inline-block',
      padding: '0.25em 0.6em',
      fontSize: '75%',
      fontWeight: '700',
      lineHeight: '1',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'baseline',
      borderRadius: '0.25rem',
      color: '#fff',
      backgroundColor: isOpen ? '#28a745' : '#dc3545',
    }}>
      {isOpen ? 'OPENING' : 'CLOSING'}
    </span>
  );

  useEffect(() => {
    //console.log("venueTimings", venueTimings);
  }, [venueTimings]);


  useEffect(() => {
    const calculateValidTimeRange = (selected) => {
      const filteredTimings = venueTimings.filter(timing => selected.includes(timing.day));

      const maxStartTime = Math.max(...filteredTimings.map(timing => new Date(`1970/01/01 ${timing.openingTime}`).getTime()));
      const minEndTime = Math.min(...filteredTimings.map(timing => new Date(`1970/01/01 ${timing.closingTime}`).getTime()));

      const validStartTime = new Date(maxStartTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
      const validEndTime = new Date(minEndTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
      const validRange = `${validStartTime} - ${validEndTime}`;

      setValidTimeRange({ startTime: validStartTime, endTime: validEndTime, range: validRange });
    };

    if (!selectedDay.length) return;
    calculateValidTimeRange(selectedDay);
  }, [selectedDay, venueTimings]);


  useEffect(() => {
    //console.log("ValidTimeRange", validTimeRange);
  }, [validTimeRange]);


  useEffect(() => {
    return () => {
      if (rectangleImagePreview) URL.revokeObjectURL(rectangleImagePreview);
      if (squareImagePreview) URL.revokeObjectURL(squareImagePreview);
      if (storyImagePreview) URL.revokeObjectURL(storyImagePreview);
    };
  }, [rectangleImagePreview, squareImagePreview, storyImagePreview]);

  useEffect(() => {
    if (!selectedDay.length) return;

    //console.log("selectedDay", selectedDay);

    // Function to find the next date for any of the selected days
    const getNextDate = (days) => {
      const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      let today = new Date();
      let nextDate = new Date();

      // Find the next date that matches one of the selected days
      for (let i = 0; i < 7; i++) {
        const dayIndex = (today.getDay() + i) % 7;
        const dayName = daysOfWeek[dayIndex];

        if (days.includes(dayName)) {
          nextDate.setDate(today.getDate() + i);
          return nextDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD
        }
      }

      return today.toISOString().split('T')[0];
    };

    // Update date input based on selected days
    const nextDate = getNextDate(selectedDay);
    setDateInputValue(nextDate);

  }, [selectedDay]);

  const isDayEnabled = (date) => {
    const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const dayName = daysOfWeek[date.getDay()];
    return selectedDay.includes(dayName);
  };

  useEffect(() => {
    if (!venueDays) return;

    const fullDayNames = {
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday',
      sun: 'Sunday'
    };

    const days = venueDays.map((abbr) => {
      const day = fullDayNames[abbr.toLowerCase()];
      return { id: abbr, name: day || abbr };
    });
    setDayList(days);
    setSelectedDay([]);
  }, [venueDays]);

  const fetchDiscount = async () => {
    setLoading(true);
    const response = await postRequest("discount/list", { limit: 10000000 });
    if (response.status == 1) {
      setDiscountList(response.data.list);
    }
    setLoading(false);
  };
  const handleCropComplete2 = (crop) => {
    setCompletedCrop2(crop);
  };

  const handleCropComplete3 = (crop) => {
    setCompletedCrop3(crop);
  };

  const handleCropComplete4 = (crop) => {
    setCropImage(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop2?.width &&
        completedCrop2?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop2,
          scale,
          rotate
        );

        setrectangleSelected(rectangleSelected + 1);
      }
    },
    100,
    [completedCrop2]
  );

  useDebounceEffect(
    async () => {
      if (
        completedCrop3?.width &&
        completedCrop3?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop3,
          scale,
          rotate
        );
        setSquareSelected(squareSelected + 1);
      }
    },
    100,
    [completedCrop3]
  );

  useDebounceEffect(
    async () => {
      if (
        cropImage?.width &&
        cropImage?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          cropImage,
          scale,
          rotate
        );
        setStorySelected(storySelected + 1);
      }
    },
    100,
    [cropImage]
  );

  
  
  const checkOfferTimes = (offerData, validTimeRange) => {
    // Helper function to convert time string to date object
    const timeStringToDate = (timeString) => {
      const [hours, minutes] = timeString?.split(':');
      return new Date(0, 0, 0, parseInt(hours, 10), parseInt(minutes, 10));
    };

    // Function to check if a given time is within the range
    const isTimeInRange = (time, startTime, endTime) => {
      return time >= startTime && time <= endTime;
    };

    // Convert the times to Date objects
    const offerStartTime = timeStringToDate(offerData.startTime);
    const offerEndTime = timeStringToDate(offerData.endTime);
    const validStartTime = timeStringToDate(validTimeRange.startTime);
    const validEndTime = timeStringToDate(validTimeRange.endTime);

    // Check if the offer start and end times are within the valid time range
    const isStartTimeValid = isTimeInRange(offerStartTime, validStartTime, validEndTime);
    const isEndTimeValid = isTimeInRange(offerEndTime, validStartTime, validEndTime);

    return { isStartTimeValid, isEndTimeValid };
  };


  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const selectRow = (id, name, venue) => {

    setSelectedDay([]);
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    setVenueTimings(venue?.timings);

    const days = venue?.timings?.map(item => item.day);
    setVenueDays(days);
    closeModalVenue();

  };

  const toggleModel = () => {
    setModel(!model);
  };

  const closeModalVenue = () => {
    setModel(false);
  };

  const toggleModelHoliday = () => {
    setModelHoliday(!modelHoliday);
  };

  const closeModalHoliday = () => {
    setModelHoliday(false);
  };

  const selectHoliday = (items) => {

    setSelectedHoliday(items);
    closeModalHoliday();
    //console.log("items", items);

  };

  const deleteHoliday = (index) => {
    const newHoliday = selectedHoliday.filter((_, idx) => idx !== index);
    setSelectedHoliday(newHoliday);
  };

  const closePackageModal = () => {
    setOpenPackage(false);
  };

  //const [day]

  const msUpdateDay = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedDay(ids);
  };

  const msUpdateCategory = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCategory(ids);
  };

  const updateDiscount = (index, value) => {
    const list = [...packages];
    list[index].discount = value;
    setPackages(list);
  };

  let history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (!state) return;

    if (state?.venueId) {
      setVenueId(state?.venueId);
      setVenueText(state?.venueName);
      setVenueImage(state?.venueLogo)
    }

    if (state?.categoryId) {
      setCategoryId(state?.categoryId);

      const defaulit = [];
      defaulit.push(state?.categoryId);

      setSelectedCategory([...defaulit]);

      const tempData = { ...offerData };
      tempData.categoryId = state?.categoryId;
      setHomeBlockData(tempData);
    }

  }, [state]);

  useEffect(() => {
    //fetchList();
    fetchCategoryList();
    //fetchPackages();
    //fetchDiscount();
    // fetch days

    const days = [
      { id: "mon", name: "Monday" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];
    setDayList(days);
  }, []);

  const fetchPackages = async () => {
    setLoading(true);
    const response = await postRequest("subscription/membership/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setPackages(response.data.list);
    }
    setLoading(false);
  };

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.name,
        };
      });
      setVenueList(list);
    }
    setLoading(false);
  };

  const renderDay = (day) => {
    const dayLowerCase = day.toLowerCase();
    const timing = venueTimings.find(t => t.day === dayLowerCase);
    if (timing) {
      return (
        <td style={{ color: 'green' }}>
          {formatTime(timing.openingTime)} to {formatTime(timing.closingTime)}
        </td>
      );
    }
    return <td style={{ color: 'red' }}>Closed</td>;
  };

  function isTimeWithinTimings(offerDate, timings) {
    return true;
    const offerTime = moment(offerDate);
    const openingTime = moment(`${offerDate.split('T')[0]}T${timings.openingTime}`);
    const closingTime = moment(`${offerDate.split('T')[0]}T${timings.closingTime}`);

    //console.log("DYA", offerTime, openingTime, closingTime)

    // Adjust for next day if closing time is earlier than opening time
    if (closingTime.isBefore(openingTime)) {
      closingTime.add(1, 'day');
    }

    //console.log("DYA", offerTime, openingTime, closingTime)

    return offerTime.isBetween(openingTime, closingTime, null, '[]');
  }

  const fetchCategoryList = async () => {
    const param = { page: 1, limit: 100000 };
    const category = await postRequest("venue/category/list/admin", param);
    if (category.status == 1) {
      const options = category.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setCategoryList(options);
      if (selectedCategory.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedCategory.includes(item.id);
        });
        setDefaultCategory(defaultItems);
      }
    }
  };
  const search = location.search;
  const from = new URLSearchParams(search).get("from");

  // const AddcreateOffer = async (e) => {
  //   e.preventDefault();
  
  //   // Validate required fields
  //   if (!offerData.title) {
  //     toast.error("Please enter title");
  //     return;
  //   }
  //   if (!selectedCategory.length) {
  //     toast.error("Please select category");
  //     return;
  //   }
  //   if (!venueId) {
  //     toast.error("Please select venue");
  //     return;
  //   }
  //   if (!selectedDay.length) {
  //     toast.error("Please select days");
  //     return;
  //   }
  //   if (packages.some(pkg => pkg.isAllowClaim)) {
  //     if (!offerData.specialOffer_title) {
  //       toast.error("Please select Special Offer Title");
  //       return false;
  //     }
  //     if (!offerData.specialOffer_discription) {
  //       toast.error("Please select Special Offer Description");
  //       return false;
  //     }
  //     if (!offerData.specialOffer_discount) {
  //       toast.error("Please select Special Offer Discount");
  //       return false;
  //     }
  //   }
  
  //   const dayMap = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  //   const startDayOfWeek = dayMap[new Date(offerData.startDate).getDay()];
  //   const endDayOfWeek = dayMap[new Date(offerData.endDate).getDay()];
  
  //   const startDayTiming = venueTimings.find(timing => timing.day === startDayOfWeek);
  //   const endDayTiming = venueTimings.find(timing => timing.day === endDayOfWeek);
  
  //   let startDate = null;
  //   let endDate = null;
  
  //   if (offerData.startTime && offerData.endTime) {
  //     const [startHours, startMinutes] = offerData.startTime.split(':').map(Number);
  //     const [endHours, endMinutes] = offerData.endTime.split(':').map(Number);
  //   }
  
  //   if (offerData.startDate) {
  //     const year = offerData.startDate.getFullYear();
  //     const month = offerData.startDate.getMonth();
  //     const day = offerData.startDate.getDate();
  //     let hours = 0, minutes = 0;
  //     if (offerData.startTime) {
  //       [hours, minutes] = offerData.startTime.split(':').map(Number);
  //     }
  //     startDate = new Date(year, month, day, hours, minutes);
  //   }
  
  //   if (offerData.endDate) {
  //     const year = offerData.endDate.getFullYear();
  //     const month = offerData.endDate.getMonth();
  //     const day = offerData.endDate.getDate();
  //     let hours = 0, minutes = 0;
  //     if (offerData.endTime) {
  //       [hours, minutes] = offerData.endTime.split(':').map(Number);
  //     }
  //     endDate = new Date(year, month, day, hours, minutes);
  //   }
  
  //   if (startDate && endDate) {
  //     if (startDate >= endDate) {
  //       toast.error("End date should be greater than start date");
  //       return;
  //     }
  //   }
  
  //   if (!selectedImage) {
  //     toast.error("Please select image");
  //     return;
  //   }
  
  //   if (!offerData.allowCliamed) {
  //     offerData.allowCliamed = "all-week";
  //   }
  
  //   if (offerData.allowCliamed === "public-holiday") {
  //     if (selectedHoliday.length === 0) {
  //       toast.error("Please select public holiday");
  //       return;
  //     }
  //   }
  
  //   let offerTiming = {};
  //   if (availableTime === "regular") {
  //     if (!regularTime?.start || !regularTime?.end) {
  //       toast.error("Please select regular time");
  //       return;
  //     }
  //     offerTiming = {
  //       type: "regular",
  //       time: `${regularTime?.start}-${regularTime?.end}`,
  //       totalSeats: offerData?.totalSeats
  //     };
  //   } else {
  //     if (!timeSlots?.length) {
  //       toast.error("Please select time slots");
  //       return;
  //     }
  //     const slots = timeSlots.map((item) => {
  //       return {
  //         time: item.start + "-" + item.end,
  //         totalSeats: item.totalSeats,
  //       };
  //     });
  //     offerTiming = {
  //       type: "slot",
  //       slots: slots
  //     };
  //   }
  
  //   const formData = new FormData();
  //   formData.append("title", offerData.title);
  //   formData.append("disclaimerTitle", offerData.disclaimerTitle || "");
  //   formData.append("description", offerData.description || "");
  //   formData.append("discountTag", offerData.discountTag || "");
  //   formData.append("disclaimerDescription", offerData.disclaimerDescription || "");
  //   formData.append("specialOffer_title", offerData.specialOffer_title || "");
  //   formData.append("specialOffer_discription", offerData.specialOffer_discription || "");
  //   formData.append("specialOffer_discount", offerData.specialOffer_discount || 0);
  //   formData.append("offerTiming", JSON.stringify(offerTiming));
  
  //   selectedDay.sort((a, b) => {
  //     const dayOrder = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  //     return dayOrder.indexOf(a) - dayOrder.indexOf(b);
  //   });
  //   formData.append("days", selectedDay.join(","));
  //   selectedCategory.forEach((item) => {
  //     formData.append("categoryId[]", item);
  //   });
  //   // formData.append("image", selectedImage);
  
  //   formData.append("venue", venueId);
  
  //   if (offerData.startDate) {
  //     formData.append("startTime", startDate);
  //   }
  //   if (offerData.endDate) {
  //     formData.append("endTime", endDate);
  //   }
  
  //   formData.append("allowCliamed", offerData.allowCliamed);
  //   const selectedHolidayIds = selectedHoliday.map((item) => item._id);
  //   selectedHolidayIds.forEach((id, index) => {
  //     formData.append(`publicHolidays[${index}]`, id);
  //   });
  
  //   const finalPackages = packages.filter((pack) => pack.title);
  
  //   finalPackages.forEach((item, index) => {
  //     formData.append(`packages_type[${index}]`, item.package_type || "regular");
  //     formData.append(`packages_id[${index}]`, item._id || "");
  //     formData.append(`packages_title[${index}]`, item.title);
  //     formData.append(`packages_reservationAmount[${index}]`, item.reservationAmount || 0);
  //     formData.append(`packages_discount[${index}]`, item.discount || 0);
  //     formData.append(`packages_amount[${index}]`, item.amount || 0);
  //     formData.append(
  //       `packages_description[${index}]`,
  //       item.packages_description || item.description || " "
  //     );
  //   });
  
  //   setStartLoader(true);
  
  //   try {
  //     const uploadPromises = [
  //       selectedImage ? postRequestForm("comman/img/upload", createFormData("image", selectedImage)) : Promise.resolve(null),
  //       remoteRectangle ? postRequestForm("comman/img/upload", createFormData("image", remoteRectangle)) : Promise.resolve(null),
  //       remoteSquare ? postRequestForm("comman/img/upload", createFormData("image", remoteSquare)) : Promise.resolve(null),
  //       remoteStory ? postRequestForm("comman/img/upload", createFormData("image", remoteStory)) : Promise.resolve(null)
  //     ];
  
  //     const [imageResponse, rectangleResponse, squareResponse, storyResponse] = await Promise.all(uploadPromises);
  
  //     appendFormData(formData, {
  //       image: imageResponse?.data?.url || '',
  //       rectangleImage: rectangleResponse?.data?.url || "",
  //       squareImage: squareResponse?.data?.url || "",
  //       storyImage: storyResponse?.data?.url || "",
  //     });
  
  //     const response = await postRequestForm("offer/add", formData);
  //     setStartLoader(false);
  //     setLoading(false);
  
  //     if (response.status === 1) {
  //       try {
  //         await pushUpdates({
  //           type: "offer",
  //           id: response.data._id,
  //           receivers: [],
  //         });
  //       } catch (error) {
  //         //console.log("pushUpdates error:", error);
  //       }
  //       toast.success(response.message);
  //       setTimeout(() => {
  //         if (!from) history.goBack();
  //         else history.push("/venuedetails/" + venueId + "?active=Offers");
  //       }, 3000);
  //     } else {
  //       toast.error(response.message);
  //     }
  //   } catch (error) {
  //     console.error("Failed to create offer:", error);
  //     toast.error("Failed to process the request.");
  //   } finally {
  //     setStartLoader(false);
  //   }
  // };

  // Helper function to create FormData


  const AddcreateOffer = async (e) => {
    e.preventDefault();
  
    // Validate required fields
    if (!offerData.title) {
      toast.error("Please enter title");
      return;
    }
    if (!selectedCategory.length) {
      toast.error("Please select category");
      return;
    }
    if (!venueId) {
      toast.error("Please select venue");
      return;
    }
    if (!selectedDay.length) {
      toast.error("Please select days");
      return;
    }
    if (packages.some(pkg => pkg.isAllowClaim)) {
      if (!offerData.specialOffer_title) {
        toast.error("Please select Special Offer Title");
        return false;
      }
      if (!offerData.specialOffer_discription) {
        toast.error("Please select Special Offer Description");
        return false;
      }
      if (!offerData.specialOffer_discount) {
        toast.error("Please select Special Offer Discount");
        return false;
      }
    }
  
    let startDate = null;
    let endDate = null;
  
    if (offerData.startTimeLabel && !offerData.endTimeLabel) {
      toast.error("Please select end time");
      return;
    }
    
    // if (offerData.startTimeLabel && offerData.endTimeLabel) {
    //   const [startHours, startMinutes] = offerData.startTimeLabel.split(':').map(Number);
    //   const [endHours, endMinutes] = offerData.endTimeLabel.split(':').map(Number);
    //   if (startHours > endHours || (startHours === endHours && startMinutes >= endMinutes)) {
    //     toast.error("End time should be greater than start time");
    //     return;
    //   }
    // }


    // if (offerData.startTimeLabel && offerData.endTimeLabel) {
    //   const parseTime = (time) => {
    //     const [timeString, period] = time.split(' ');
    //     let [hours, minutes] = timeString.split(':').map(Number);
    //     if (period === 'PM' && hours !== 12) {
    //       hours += 12;
    //     } else if (period === 'AM' && hours === 12) {
    //       hours = 0;
    //     }
    //     return { hours, minutes };
    //   };
    
    //   const convertToMinutes = ({ hours, minutes }) => hours * 60 + minutes;
    
    //   const startTime = parseTime(offerData.startTimeLabel);
    //   const endTime = parseTime(offerData.endTimeLabel);
    
    //   const startMinutes = convertToMinutes(startTime);
    //   const endMinutes = convertToMinutes(endTime);
    
    //   // Check if end time is greater than start time considering overnight scenarios
    //   if (startMinutes >= endMinutes && endMinutes <= 720) {
    //     toast.error("End time should be greater than start time");
    //     return;
    //   }
    // }
    
  
    if (offerData.startTime) {
      const year = offerData.startTime.getFullYear();
      const month = offerData.startTime.getMonth();
      const day = offerData.startTime.getDate();
      let hours = 0, minutes = 0;
      if (offerData.startTimeLabel) {
        [hours, minutes] = offerData.startTimeLabel.split(':').map(Number);
      }
      startDate = new Date(year, month, day, hours, minutes);
    }
  
    if (offerData.endTime) {
      const year = offerData.endTime.getFullYear();
      const month = offerData.endTime.getMonth();
      const day = offerData.endTime.getDate();
      let hours = 0, minutes = 0;
      if (offerData.endTimeLabel) {
        [hours, minutes] = offerData.endTimeLabel.split(':').map(Number);
      }
      endDate = new Date(year, month, day, hours, minutes);
    }
  
    // if (startDate && endDate && startDate >= endDate) {
    //   toast.error("End date should be greater than start date");
    //   return;
    // }
  
    if (!selectedImage) {
      toast.error("Please select image");
      return;
    }
  
    if (!offerData.allowCliamed) {
      offerData.allowCliamed = "all-week";
    }
  
    if (offerData.allowCliamed === "public-holiday" && selectedHoliday.length === 0) {
      toast.error("Please select public holiday");
      return;
    }
  
    let offerTiming = {};
    if (availableTime === "regular") {
      if (!regularTime?.start || !regularTime?.end) {
        toast.error("Please select regular time");
        return;
      }
      offerTiming = {
        type: "regular",
        time: `${regularTime?.start}-${regularTime?.end}`,
        totalSeats: offerData?.totalSeats
      };
    } else {
      if (!timeSlots?.length) {
        toast.error("Please select time slots");
        return;
      }
      const slots = timeSlots.map((item) => ({
        time: item.start + "-" + item.end,
        totalSeats: item.totalSeats,
      }));
      offerTiming = {
        type: "slot",
        slots
      };
    }
  
    const formData = new FormData();
    formData.append("title", offerData.title.trim() || "");
    formData.append("disclaimerTitle", offerData.disclaimerTitle || "");
    formData.append("description", offerData.description || "");
    formData.append("discountTag", offerData.discountTag || "");
    formData.append("disclaimerDescription", offerData.disclaimerDescription || "");
    formData.append("specialOffer_title", offerData.specialOffer_title || "");
    formData.append("specialOffer_discription", offerData.specialOffer_discription || "");
    formData.append("specialOffer_discount", offerData.specialOffer_discount || 0);
    formData.append("offerTiming", JSON.stringify(offerTiming));
  
    selectedDay.sort((a, b) => {
      const dayOrder = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
      return dayOrder.indexOf(a) - dayOrder.indexOf(b);
    });
    formData.append("days", selectedDay.join(","));
    selectedCategory.forEach((item) => {
      formData.append("categoryId[]", item);
    });
  
    formData.append("venue", venueId);
    if (offerData?.startTime) formData.append("startTime", startDate.toISOString());
    if (offerData?.endTime) formData.append("endTime", endDate.toISOString());
    if (offerData?.startTimeLabel) formData.append("startTimeLabel", offerData.startTimeLabel);
    if (offerData?.endTimeLabel) formData.append("endTimeLabel", offerData.endTimeLabel);
    formData.append("allowCliamed", offerData.allowCliamed);
  
    const selectedHolidayIds = selectedHoliday.map((item) => item._id);
    selectedHolidayIds.forEach((id, index) => {
      formData.append(`publicHolidays[${index}]`, id);
    });
  
    const finalPackages = [...packages];
    finalPackages.forEach((item, index) => {
      formData.append(`packages_type[${index}]`, item.package_type || "regular");
      formData.append(`packages_id[${index}]`, item._id || "");
      formData.append(`packages_title[${index}]`, item.title || "");
      formData.append(`packages_reservationAmount[${index}]`, item.reservationAmount || 0);
      formData.append(`packages_discount[${index}]`, item.discount || 0);
      formData.append(`packages_amount[${index}]`, item.amount || 0);
      formData.append(`packages_description[${index}]`, item.packages_description || item.description || " ");
    });
  
    setStartLoader(true);
  
    try {
      const uploadPromises = [
        selectedImage ? postRequestForm("comman/img/upload", createFormData("image", selectedImage)) : Promise.resolve(null),
        remoteRectangle ? postRequestForm("comman/img/upload", createFormData("image", remoteRectangle)) : Promise.resolve(null),
        remoteSquare ? postRequestForm("comman/img/upload", createFormData("image", remoteSquare)) : Promise.resolve(null),
        remoteStory ? postRequestForm("comman/img/upload", createFormData("image", remoteStory)) : Promise.resolve(null)
      ];
  
      const [imageResponse, rectangleResponse, squareResponse, storyResponse] = await Promise.all(uploadPromises);
  
      appendFormData(formData, {
        image: imageResponse?.data?.url || '',
        rectangleImage: rectangleResponse?.data?.url || "",
        squareImage: squareResponse?.data?.url || "",
        storyImage: storyResponse?.data?.url || "",
      });
  
      const response = await postRequestForm("venue/offer/add", formData);
      setStartLoader(false);
      setLoading(false);
  
      if (response.status === 1) {
        try {
          await pushUpdates({
            type: "offer",
            id: response.data._id,
            receivers: [],
          });
        } catch (error) {
          //console.log("pushUpdates error:", error);
        }
        toast.success(response.message);
        setTimeout(() => {
          // if (!from) history.goBack();
          // else history.push("/venuedetails/" + venueId + "?active=Offers");

          history.goBack();
        }, 3000);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Failed to create offer:", error);
      toast.error("Failed to process the request.");
      setStartLoader(false);
      setLoading(false);
    } finally {
      setStartLoader(false);
      setLoading(false);
    }
  };



  const createFormData = (key, file) => {
  const formData = new FormData();
  formData.append(key, file);
  return formData;
};

  const appendFormData = (formData, data) => {
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
  };

  const updateValue = (index, value) => {
    const list = { ...offerData };
    list[index] = value;
    setHomeBlockData(list);
  };

  const addPackage = () => {

    // reset currentPackageData

    setCurrentPackageData({});


    setOpenPackage(true);
    // setPackages([
    //   ...packages,
    //   { title: "", amount: "", discount: "", qty: "", isFeatured: false },
    // ]);
  };

  // const addPackageData = (data) => {

  //   // check editingIndex is not -1

  //   if (editingIndex !== -1) {
  //     const newPackages = [...packages];
  //     newPackages[editingIndex] = data;
  //     setPackages(newPackages);
  //     setCurrentPackageData({});
  //     setEditingIndex(-1);
  //     setOpenPackage(false);
  //     return;
  //   }

  //   setPackages([...packages, data]);
  //   setOpenPackage(false);
  // };



  const addPackageData = (data) => {
    let newData = { ...data }; // Make a copy of the data to avoid mutating the original object
    let newPackages = [...packages];
    // If the new package is marked as featured, unselect any previously selected featured packages
    if (newData.packages_isFeatured) {
      newPackages = newPackages.map(pkg => {
        pkg.packages_isFeatured=false
        return pkg;
      });
      // setPackages(newPackages);
    }
  
    // Check if editingIndex is not -1 (i.e., if we're editing an existing package)
    if (editingIndex !== -1) {
      newPackages[editingIndex] = newData;
      setPackages(newPackages);
      setCurrentPackageData({});
      setEditingIndex(-1);
      setOpenPackage(false);
    } else {
      setPackages([...newPackages, newData]);
      setOpenPackage(false);
    }
  };



  // const updatePackageData = (index, field, value) => {
  //   const newPackages = [...packages];
  //   newPackages[index][field] = value;
  //   setPackages(newPackages);
  // };


  const updatePackageData = (index, field, value) => {
    const newPackages = packages.map((pkg, i) => {
      if (i === index) {
        return { ...pkg, [field]: value };
      } else if (field === "packages_isFeatured" && pkg.packages_isFeatured) {
        // Uncheck any other checkbox if it was checked
        return { ...pkg, packages_isFeatured: false };
      }
      return pkg;
    });
    
    const selectedCount = newPackages.filter(pkg => pkg.packages_isFeatured).length;
  
    if (selectedCount > 1) {
      alert("Please select only one isFeatured checkbox.");
      return;
    }
  
    setPackages(newPackages);
  };
  


  const saveEditedPackage = (index) => {

    // check title and amount is not empty

    // if (!packages[index].title) {
    //   toast.error("Please enter title");
    //   return;
    // }

    if (!packages[index].amount) {
      toast.error("Please enter amount");
      return;
    }

    // check amount is number

    if (isNaN(packages[index].amount)) {
      toast.error("Amount must be number");
      return;
    }

    // check amount is not negative

    if (packages[index].amount < 1) {
      toast.error("Amount must be greater than 0");
      return;
    }

    // check discount 0 - 100

    if (packages[index].discount) {
      if (isNaN(packages[index].discount)) {
        toast.error("Discount must be number");
        return;
      }

      if (packages[index].discount < 5) {
        toast.error("Discount must be 5 or greater than 5 ");
        return;
      }

      if (packages[index].discount > 99) {
        toast.error("Discount must be less than 100");
        return;
      }

    }

    // check qty is number

    if (packages[index].qty) {
      if (isNaN(packages[index].qty)) {
        toast.error("Qty must be number");
        return;
      }

      if (packages[index].qty < 0) {
        toast.error("Qty must be greater than 0");
        return;
      }
    }

    // check cliam amount is <= amount

    if (packages[index].pricePerBrunch) {
      if (isNaN(packages[index].pricePerBrunch)) {
        toast.error("Claim amount must be number");
        return;
      }

      //console.log("packages[index].pricePerBrunch", packages[index].pricePerBrunch, packages[index].amount)

      if (packages[index].pricePerBrunch < 0) {
        toast.error("Claim amount must be greater than 0");
        return;
      }

      if (parseInt(packages[index].pricePerBrunch) > parseInt(packages[index].amount)) {
        toast.error("Claim amount must be less than amount");
        return;
      }
    }




    // Add any validation logic here before saving

    // cliam code must be 8 digit
    //   if (packages[index].claimCode){
    //   if(packages[index].claimCode.length !== 8) {
    //     toast.error("Claim code must be  8 digit");
    //     return;

    //   }
    //   if (/^[A-Za-z]{2}\d{6}$/.test(packages[index].claimCode) === false) {
    //     toast.error("Claim code must be 2 letter and 6 digit");
    //     return;
    //   }
    // }

    setEditingIndex(null); // Reset editing index
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((_, idx) => idx !== index);
    setPackages(newPackages);
  };


  const editPackage = (index) => {
    setEditingIndex(index);
    let currentPackageDt = packages[index];
    setCurrentPackageData({ ...currentPackageDt });
    setOpenPackage(true);
  };




  const [allAllowSale, setAllAllowSale] = React.useState(false);
  const allAllowSaleSelected = (e) => {
    let value = e.target.value;
    setAllAllowSale(!allAllowSale)
    if (!allAllowSale) {

      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowSale: true }
      });
      setPackages(updatedPackages);
    } else {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowSale: false }
      });
      setPackages(updatedPackages);
    }
  };
  const [allAllowClaim, setAllAllowClaim] = React.useState(false);
  const allAllowClaimSelected = (e) => {
    let value = e.target.value;
    setAllAllowClaim(!allAllowClaim)
    if (!allAllowClaim) {

      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowClaim: true }
      });
      setPackages(updatedPackages);
    } else {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowClaim: false }
      });
      setPackages(updatedPackages);
    }
  };

  const deleteSlot = (index) => {
    const updatedSlots = [...timeSlots];
    updatedSlots.splice(index, 1);
    setTimeSlots(updatedSlots);
  };

  const updateRegulaTime = (type, value) => {
    const updatedSlots = { ...regularTime };
    updatedSlots[type] = value;
    setRegularTime(updatedSlots);
  };

  const addSlot = () => {
    setTimeSlots([...timeSlots, { start: "", end: "", totalSeats: 0 }]);
  };

  const updateSlot = (index, type, value) => {
    const updatedSlots = [...timeSlots];
    updatedSlots[index][type] = value;
    setTimeSlots(updatedSlots);
  };


  const handleRectangleImageChange = (e) => {
    const file = e.target.files[0];
    setRectangleImage(file);
    setRectangleImagePreview(URL.createObjectURL(file));
  };
  
  const handleSquareImageChange = (e) => {
    const file = e.target.files[0];
    setSquareImage(file);
    setSquareImagePreview(URL.createObjectURL(file));
  };
  
  const handleStoryImageChange = (e) => {
    const file = e.target.files[0];
    setStoryImage(file);
    setStoryImagePreview(URL.createObjectURL(file));
  };

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }

      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], offerData.title + "-rectangle.jpg", {
        type: "image/jpeg",
      });
      setRemoteRectangle(file);
    });
  }, [rectangleSelected]);

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }

      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], offerData.title + "-square.jpg", {
        type: "image/jpeg",
      });

      setRemoteSquare(file);
    });
  }, [squareSelected]);

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }

      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], offerData.title + "-story.jpg", {
        type: "image/jpeg",
      });

      setRemoteStory(file);
    });

  }, [storySelected]);


  return (
    <React.Fragment>
      <ToastContainer />
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>Offer Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Offer Management</li>:{" "}
              <li className="breadcrumb-item">Create Offer</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
        {state?.type == "category" && (
          <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() =>
                history.push(`categorydetails/${state?.categoryId}`)
              }
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
        )}
      </Row>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create offer </h4>
                <Form onSubmit={AddcreateOffer}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              value={offerData?.title}
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={offerData?.description}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Tag
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("discountTag", e.target.value)
                              }
                              value={offerData?.discountTag}
                              name="discountTag"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Disclaimer Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              value={offerData?.disclaimerTitle}
                              onChange={(e) =>
                                updateValue("disclaimerTitle", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Disclaimer Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("disclaimerDescription", e.target.value)
                              }
                              value={offerData?.disclaimerDescription}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        {state?.categoryId ? null : (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Category
                            </label>
                            <div className="col-sm-6">
                              <MultiSelect
                                options={categoryList}
                                onSelect={msUpdateCategory}
                                selectedValues={defaultCategory}
                                placeholder="Select Category"
                              />
                            </div>
                          </div>
                        )}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Venue
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => {
                              if (from) {
                                // Do nothing or handle the case where 'from' is present
                              } else {
                                toggleModel(); // Open the model when 'from' is not present
                              }
                            }}
                          >
                            {venueText ? (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              // <div style={{ minWidth: "100%" }}>
                              //   <a
                              //     href="javascript:void(0)"
                              //     style={{
                              //       textDecoration: "none",
                              //       color: "#5b626b",
                              //       fontSize: "0.8125rem",
                              //       fontWeight: 400,
                              //     }}
                              //   >
                              //     {venueText}
                              //   </a>
                              // </div>
                              // <div>
                              // <a
                              //     onClick={() => toggleModel()}
                              //     href="javascript:void(0)"
                              //   >
                              //     <i
                              //       style={{ color: "#5b626b" }}
                              //       class="fa fa-plus"
                              //     ></i>
                              //   </a>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={venueImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={venueText}
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            ) : (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              //   <div style={{ minWidth: "100%" }}>
                              //     <a
                              //       href="javascript:void(0)"
                              //       style={{
                              //         textDecoration: "none",
                              //         color: "#5b626b",
                              //         fontSize: "0.8125rem",
                              //         fontWeight: 400,
                              //       }}
                              //     >
                              //       Select Venue
                              //     </a>
                              //   </div>
                              //   <div>
                              //     <a
                              //       onClick={() => toggleModel()}
                              //       href="javascript:void(0)"
                              //     >
                              //       <i
                              //         style={{ color: "#5b626b" }}
                              //         class="fa fa-plus"
                              //       ></i>
                              //     </a>
                              //   </div>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Days
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={dayList}
                              onSelect={msUpdateDay}
                              selectedValues={[]}
                              placeholder="Select Days"
                            />
                          </div>
                        </div>

                        {venueTimings && venueTimings.length > 0 && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Venue Timings
                            </label>
                            <div className="col-sm-6">

                              <div className="venue-timings-table">
                                {venueTimings.map((timing, index) => (
                                  <div key={index} className="timing-row" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto', alignItems: 'center', borderBottom: '1px solid #ccc' }}>
                                    <div className="day-cell" style={{ padding: '8px', backgroundColor: isClosed(timing) ? '#f8f9fa' : 'transparent', gridColumn: isClosed(timing) ? '1 / span 3' : '1' }}>
                                      {timing.day.charAt(0).toUpperCase() + timing.day.slice(1)}
                                    </div>
                                    {!isClosed(timing) && (
                                      <>
                                        <div className="status-cell" style={{ padding: '8px' }}>
                                          {renderStatusBadge(true)}
                                        </div>
                                        <div className="time-cell" style={{ padding: '8px' }}>
                                          {timing.openingTime} - {timing.closingTime}
                                        </div>
                                      </>
                                    )}
                                    {isClosed(timing) && (
                                      <div className="status-cell" style={{ padding: '8px' }}>
                                        {renderStatusBadge(false)}
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>

                            </div>
                          </div>
                        )}

                       

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Start Date</label>
                          <div className="col-sm-6">
                            <DatePicker
                              selected={offerData.startTime}
                              onChange={date => updateValue("startTime", date)}
                              filterDate={isDayEnabled}
                              className="form-control"
                              minDate={new Date()}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Start Time</label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) => updateValue("startTimeLabel", e.target.value)}
                              name="startTime"
                              className="form-control"
                            />
                            {validTimeRange?.range && (<><br /><span style={{ marginTop: "32px", fontWeight: "bold", fontSize: "14px", display: "inline-block" }}>Valid Range: {validTimeRange?.range} (24 Hour Format)</span></>)}
                          </div>
                        </div>
                       
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">End Date</label>
                          <div className="col-sm-6">
                            <DatePicker
                              selected={offerData.endTime}
                              onChange={date => updateValue("endTime", date)}
                              filterDate={isDayEnabled}
                              className="form-control"
                              minDate={new Date()}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">End Time</label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) => updateValue("endTimeLabel", e.target.value)}
                              name="endTimeLabel"
                              className="form-control"
                            />
                            {validTimeRange?.range && (<><br /><span style={{ marginTop: "32px", fontWeight: "bold", fontSize: "14px", display: "inline-block" }}>Valid Range: {validTimeRange?.range} (24 Hour Format)</span></>)}
                          </div>
                        </div>

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Whosin
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("allowWhosin", e.target.value)
                              }
                              name="allowWhosin"
                              className="form-select"
                            >
                              <option value="1">Yes</option>
                              <option value="0" selected>
                                No
                              </option>
                            </select>
                          </div>
                        </div> */}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Cliamed On
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("allowCliamed", e.target.value)
                              }
                              name="allowWhosin"
                              className="form-select"
                              value={offerData?.allowCliamed || "all-week"}
                            >
                              <option value="">Selcted when to claim</option>
                              <option value="all-week" selected>All week</option>
                              <option value="weekeneds">Weekends</option>
                              <option value="weekdays">Weekdays</option>
                              <option value="public-holiday">Public Holidays</option>
                            </select>
                          </div>
                        </div>





                        {offerData?.allowCliamed === "public-holiday" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Holiday's List
                            </label>
                            <div className="col-sm-8">
                              <button
                                type="button"
                                onClick={toggleModelHoliday}
                                className="btn btn-primary mb-3"
                              >
                                Select Holiday
                              </button>
                              <div className="table-responsive">

                                <table className="table table-bordered">

                                  <thead>
                                    <tr>
                                      <th className="col-2">Holiday Name</th>
                                      <th className="col-2">Holiday Date</th>
                                      <th className="col-1">Action</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {selectedHoliday.map((item, index) => (
                                      <tr key={index}>
                                        <td className="col-2">
                                          {item.title}
                                        </td>
                                        <td className="col-2">
                                          {item.date}
                                        </td>
                                        <td className="col-1">
                                          <button
                                            type="button"
                                            onClick={() => deleteHoliday(index)}
                                            className="btn btn-danger mb-3"
                                          >
                                            Delete
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>

                              </div>
                            </div>
                          </div>
                        )}


                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Select Avilable Time for Booking
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-select"
                              onChange={(e) => setAvailableTime(e.target.value)}
                              defaultValue={availableTime}
                            >
                              <option value="">Select Time</option>
                              <option value="regular">Regular</option>
                              <option value="slot">Multiple Slot</option>
                            </select>
                          </div>
                        </div>

                        {availableTime === "regular" && (
                          <>
                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Quantity of Seats
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    updateValue("totalSeats", e.target.value)
                                  }
                                  defaultValue={offerData?.totalSeats}
                                  name="discount"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Start Time / End Time
                              </label>
                              <div className="col-sm-3">
                                <label>Start Time:</label>
                                <input
                                  type="time"
                                  onChange={(e) =>
                                    updateRegulaTime("start", e.target.value)
                                  }
                                  className="form-control"
                                />
                              </div>
                              <div className="col-sm-3">
                                <label>End Time:</label>
                                <input
                                  type="time"
                                  onChange={(e) =>
                                    updateRegulaTime("end", e.target.value)
                                  }
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {availableTime === "slot" && (
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-3 col-form-label"
                            >
                              Select Slots
                            </label>

                            <div className="col-sm-6">
                              {timeSlots.map((slot, index) => (
                                <div className="col-sm-12" key={index}>
                                  <div
                                    class="row"
                                    style={{ marginBottom: "16px" }}
                                  >
                                    <div className="col-sm-3">
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={slot.start}
                                        onChange={(e) =>
                                          updateSlot(
                                            index,
                                            "start",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="col-sm-3">
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={slot.end}
                                        onChange={(e) =>
                                          updateSlot(
                                            index,
                                            "end",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="col-sm-3">
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={slot.totalSeats}
                                        onChange={(e) =>
                                          updateSlot(
                                            index,
                                            "totalSeats",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="col-sm-3">
                                      <button
                                        type="button"
                                        style={{
                                          color: "white",
                                          backgroundColor: "red",
                                          border: "none",
                                          padding: "10px 20px",
                                        }}
                                        onClick={() => deleteSlot(index)}
                                      >
                                        Delete Slot
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}

                              <button
                                type="button"
                                style={{
                                  color: "white",
                                  backgroundColor: "green",
                                  border: "none",
                                  padding: "10px 20px",
                                }}
                                onClick={addSlot}
                              >
                                Add Slot
                              </button>
                            </div>
                          </div>
                        )}


                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "50px", height: "50px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Rectangle Image</label>
                        <div className="col-sm-6">
                          <input type="file" onChange={handleRectangleImageChange} name="rectangleImage" className="form-control" />
                          <br />                         

                          {rectangleImagePreview ? (
                             <ReactCrop
                             crop={cropRectangle}
                             src={rectangleImagePreview}
                             onChange={(newCrop) => updateCropRectangle(newCrop)}
                             onComplete={handleCropComplete2}
                             style={{
                               maxWidth: "720px",
                               maxHeight: "600px",
                             }}
                             >
                             
                             <img ref={imgRef} src={rectangleImagePreview} />
                            </ReactCrop>
                         
                          ) : rectangleImagePreview ? (
                            <img
                              style={{ width: "180px", height: "100px" }}
                              src={rectangleImagePreview}
                              onClick={() => showLightBox2(rectangleImagePreview)}
                            />
                          ) : null}

                          {completedCrop2 && (
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: completedCrop2.width,
                                  height: completedCrop2.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Square Image</label>
                        <div className="col-sm-6">
                          <input type="file" onChange={handleSquareImageChange} name="squareImage" className="form-control" />
                          <br />
                          {squareImagePreview ? (
                             <ReactCrop
                             crop={cropSquare}
                             src={squareImagePreview}
                             onChange={(newCrop) => updateCropSquare(newCrop)}
                             onComplete={handleCropComplete3}
                             style={{
                               maxWidth: "720px",
                               maxHeight: "600px",
                             }}
                             >
                             
                             <img ref={imgRef} src={squareImagePreview} />
                            </ReactCrop>
                            
                          ): squareImagePreview ? (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              src={squareImagePreview}
                              onClick={() => showLightBox2(squareImagePreview)}
                            />
                          ) : null}

                          {completedCrop3 && (
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: completedCrop3.width,
                                  height: completedCrop3.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Story Image</label>
                        <div className="col-sm-6">
                          <input type="file" onChange={handleStoryImageChange} name="storyImage" className="form-control" />
                          <br />
                          {storyImagePreview ? (
                            <ReactCrop
                            crop={crop}
                            src={storyImagePreview}
                            onChange={(newCrop) => setCrop(newCrop)}
                            onComplete={handleCropComplete4}
                            style={{
                              maxWidth: "720px",
                              maxHeight: "600px",
                            }}
                            >
                            
                            <img ref={imgRef} src={storyImagePreview} />
                           </ReactCrop>
                           
                          ): storyImagePreview ? (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              src={storyImagePreview}
                              onClick={() => showLightBox2(storyImagePreview)}
                            />
                          ) : null}

                          {cropImage && (
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: cropImage.width,
                                  height: cropImage.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Enter Packages Discounts
                          </label>
                          <div className="col-sm-6">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Package Title</th>
                                  <th>Package Amount</th>
                                  <th>Package Sub title</th>
                                  <th>Select Offer Discount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages.map((item, index) => (
                                  <tr>
                                    <td>{item.title}</td>
                                    <td>
                                      {item.discountedPrice} / {item.time}
                                    </td>
                                    <td>{item.subTitle}</td>
                                    <td>
                                      <input
                                        type="text"
                                        onChange={(e) =>
                                          updateDiscount(index, e.target.value)
                                        }
                                        name="discount"
                                        className="form-control"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div> */}

                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Enter Packages Discounts
                          </label>
                          <div className="col-sm-8">
                            <button
                              type="button"
                              onClick={addPackage}
                              className="btn btn-primary mb-3"
                            >
                              Add Package
                            </button>
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th className="col-2">Package Type</th>
                                    <th className="col-2">Package Title</th>
                                    <th className="col-2">
                                      Package Description
                                    </th>
                                    <th className="col-1">Package Amount</th>
                                    <th className="col-1">Package Discount</th>
                                    <th className="col-1">Reservation Amount %</th>
                                    {/* <th className="col-1">Claim Code</th> */}
                                   
                                    {/* <th className="col-1">Is Featured</th> */}
                                    <th className="col-1">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {packages.map((item, index) => (
                                    <tr key={index}>
                                      <td className="col-2">
                                        {item?.package_type || "Regular"}
                                      </td>
                                      <td className="col-2">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="text"
                                            value={item.title}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "title",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.title
                                        )}
                                      </td>
                                      <td className="col-2">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="text"
                                            defaultValue={
                                              item.packages_description
                                                ? item.packages_description
                                                : item.description
                                            }
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "packages_description",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : item.packages_description ? (
                                          item.packages_description
                                        ) : (
                                          item.description
                                        )}
                                      </td>      

                                      <td className="col-1">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="number"
                                            value={item.amount || 0}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "amount",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.amount || 0
                                        )}
                                      </td>                            
                                      <td className="col-1">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="number"
                                            value={item.discount || 0}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "discount",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.discount || 0
                                        )}
                                      </td>

                                      <td className="col-1">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="number"
                                            value={item.reservationAmount || 0}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "reservationAmount",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.reservationAmount || 0
                                        )}
                                      </td>



                                      {/* <td className="col-1">
                                        {editingIndex === index ? (
                                          <input
                                            type="text"
                                            value={item.claimCode}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "claimCode",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.claimCode
                                        )}
                                      </td> */}
                                    

                                      {/* <td className="col-1">
                                        <input
                                          type="checkbox"
                                          checked={item.isFeatured}
                                          onChange={(e) =>
                                            updatePackageData(
                                              index,
                                              "isFeatured",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </td> */}

                                      <td className="col-1">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {/* {editingIndex === index ? (
                                            <button
                                              type="button"
                                              className="btn btn-success btn-sm me-1"
                                              onClick={() => {
                                                saveEditedPackage(index);
                                              }}
                                            >
                                              Save
                                            </button>
                                          ) : (
                                            <span
                                              role="button"
                                              className="btn btn-primary btn-sm me-1"
                                              onClick={() => {
                                                setEditingIndex(index);
                                              }}
                                            >
                                              Edit
                                            </span>
                                          )} */}

                                          <i
                                            className="fas fa-pencil-alt"
                                            onClick={() => editPackage(index)}
                                            style={{ cursor: "pointer", color: "#E22A2A" }}
                                          ></i>


                                          <i
                                            className="fas fa-trash-alt mx-3"
                                            onClick={() => deletePackage(index)}
                                            style={{ cursor: "pointer", color: "red" }}
                                          ></i>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>



                        {packages.some(pkg => pkg.isAllowClaim) && (<>
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Special Offer Title
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                value={offerData?.specialOffer_title}
                                onChange={(e) =>
                                  updateValue("specialOffer_title", e.target.value)
                                }
                                name="title"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Special Offer Description
                            </label>
                            <div className="col-sm-6">
                              <textarea
                                type="textarea"
                                onChange={(e) =>
                                  updateValue("specialOffer_discription", e.target.value)
                                }
                                value={offerData?.specialOffer_discription}
                                name="specialOffer_discription"
                                className="form-control"
                                id="horizontal-password-input"
                              />
                            </div>
                          </div>

                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Special Offer Discount
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="number"
                                value={offerData?.specialOffer_discount}
                                onChange={(e) =>
                                  updateValue("specialOffer_discount", e.target.value)
                                }
                                name="specialOffer_discount"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </>)}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}

      <OfferPackageModal
        show={openPackage}
        closeModal={closePackageModal}
        currentPackageData={currentPackageData}
        addData={addPackageData}
      />

      <VenueModal
        show={model}
        closeModal={closeModalVenue}
        selectRow={selectRow}
      />

      <HolidayModal
        showUser={modelHoliday}
        closeUserModal={closeModalHoliday}
        onSelectUser={selectHoliday}
      />

      <FileUpload message="Logo & Cover Uploading" status={startLoader} />

      {/* modal */}
    </React.Fragment>
  );
}
